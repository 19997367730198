import React, { useState, useEffect } from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { Select, InputNumber, Button, Input, message } from 'antd'
import { CustomerItem, Segment } from 'DimensionsFiltersTypes'

import CommonInputs from '../../Inputs/CommonInputs'
import TimeInput from './Customer.SegmentScreen.TimeInput'
import useFetchDimensions from './useFetchDimensions'
import { stringify } from 'query-string'
import { TREE_DIMENSIONS } from 'Containers/DimensionFilters/constants'
import TreeSelect from 'Containers/DimensionFilters/Inputs/CommonInputs/TreeSelect'
import SearchDropdown from 'Containers/DimensionFilters/Inputs/CommonInputs/SearchDropdown'
import useInfiniteScroll from 'react-infinite-scroll-hook'

export type props = {
  segment: CustomerItem
  isEditing?: boolean
  initialValues?: Segment
  onOk: (value: any) => void
  onCancel: () => void
}

const SegmentScreen: React.FC<props> = ({ segment, onCancel, onOk, isEditing, initialValues }) => {
  const [value, setValue] = useState<string>('')
  const [operator, setOperator] = useState<string>('')
  const { options, setKeyword, hasMore, loadMore, loading } = useFetchDimensions({
    dimension_code: convertSegmentCodeToDimensionCode(segment?.code),
  })

  function convertSegmentCodeToDimensionCode(code: string) {
    switch (code) {
      case 'webCategoryAddToCart':
      case 'appCategoryAddToCart':
        return 'PCM_PURCHASED_CATEGORY_COUNT'

      default:
        return 'PCM_PURCHASED_PRODUCT'
    }
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    // Tránh gọi loadMore khi mới mount
    delayInMs: 100,
    // Chỉ load khi scroll đến gần cuối
    rootMargin: '0px 0px 100px 0px',
  })

  // This is the magic code :((
  const isRenderProductList = ['appAddToCart', 'webAddToCart'].includes(segment?.code)

  const getOptions = () => {
    if (segment.values.length > 0) {
      return segment.values.map((v) => ({ label: v?.name, value: v?.code, ...v }))
    }
    return options
  }
  const checkNumRegex =
    /[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_<>=^\s+?|$!@#$%\\^&*)(_'"`~/:;{}\[\]-][a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_<>=^\s+?|$!@#$%\\^&*)(_'"`~/\[\]-]*/
  function isJsonString(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  useEffect(() => {
    setValue(initialValues?.value || '')
    initialValues?.operator && setOperator(initialValues?.operator)
  }, [initialValues])

  const renderInput = () => {
    if (segment.field === 'number') {
      return (
        <Input
          style={{ height: 40, borderRadius: 4, width: 150 }}
          placeholder="Value"
          value={value}
          onChange={(e) => {
            e.target.value
              .toString()
              .split(',')
              .filter((e) => e != '')
              .some((e) => e.normalize('NFC').match(checkNumRegex)) &&
              message.warning("Invalid input: input couldn't be text", 0.75)
            setValue(e.target.value.toString())
          }}
        />
      )
    } else {
      return (
        <Input
          style={{ height: 40, borderRadius: 4, width: 150 }}
          placeholder="Value"
          value={value}
          onChange={(e) => {
            !e.target.value
              .toString()
              .split(',')
              .filter((e) => e != '')
              .every((e) => e.normalize('NFC').match(checkNumRegex)) &&
              message.warning("Invalid input: input couldn't be number", 0.75)
            setValue(e.target.value.toString())
          }}
        />
      )
    }
  }
  const isJson = isJsonString(value)

  const renderOptionsInput = () => {
    if (TREE_DIMENSIONS?.includes(segment?.code)) {
      return (
        <TreeSelect
          values={value ? (isJson ? JSON.parse(value) : value?.split(';')) : []}
          onChange={(value) => {
            setValue(JSON?.stringify(value))
          }}
          handleSearch={(keyword) => setKeyword(keyword)}
          options={options}
        />
      )
    }

    if (segment?.values?.length || isRenderProductList) {
      if (operator === 'IN_THE_FOLLOWING' || operator === 'NOT_IN_THE_FOLLOWING') {
        return (
          <SearchDropdown
            segmentType={segment?.code}
            placeholder="Value"
            values={value ? (isJson ? JSON.parse(value) : value?.split(';')) : []}
            options={getOptions()}
            isMulti
            onChange={(value) => {
              setValue(JSON?.stringify(value))
            }}
            handleSearch={(keyword) => setKeyword(keyword)}
            hasMore={hasMore}
            scrollRef={rootRef}
            loading={loading}
            sentryRef={sentryRef}
          />
        )
      } else if (operator === 'IS_BETWEEN' || operator === 'IS_NOT_BETWEEN') {
        return (
          <SearchDropdown
            segmentType={segment?.code}
            placeholder="Value"
            values={value ? value?.split(',').slice(-2) : []}
            options={getOptions()}
            isMulti
            onChange={(value) => {
              setValue(value.toString().split(',').slice(-2).join(',').toString())
            }}
            handleSearch={(keyword) => setKeyword(keyword)}
            hasMore={hasMore}
            scrollRef={rootRef}
            loading={loading}
            sentryRef={sentryRef}
          />
        )
      } else {
        return (
          <SearchDropdown
            segmentType={segment?.code}
            placeholder="Value"
            values={value ? [value] : []}
            options={getOptions()}
            onChange={(value) => setValue(value?.[0]?.toString() || '')}
            handleSearch={(keyword) => setKeyword(keyword)}
            hasMore={hasMore}
            scrollRef={rootRef}
            loading={loading}
            sentryRef={sentryRef}
          />
        )
      }
    } else if (operator === 'IS_BETWEEN' || operator === 'IS_NOT_BETWEEN') {
      return renderInput()
    } else {
      return renderInput()
    }
  }

  return (
    <div className="w-full h-full ml-4 p-4">
      <div
        className="CustomerSegmentScreenComponent flex items-center text-sm cursor-pointer"
        onClick={onCancel}
        style={{ width: 'fit-content' }}
      >
        <LeftOutlined className="cursor-pointer" />
        <span className="ml-2">{segment?.name}</span>
      </div>

      <div className="flex mt-6 items-center">
        <span className="mr-2">{segment?.name}</span>
        <div className="mr-2">
          <CommonInputs
            placeholder="Select operator"
            value={operator?.toString() || undefined}
            options={segment?.operator?.map((op) => ({ label: op.name, value: op.code }))}
            type="SINGLE_SELECT"
            onChange={(v) => {
              setOperator(v?.toString())
              setValue('')
            }}
          />
        </div>
        <div className="mr-2">{renderOptionsInput()}</div>

        <Button
          onClick={() => {
            onOk({
              type: segment.code,
              operator,
              value,
            })
          }}
          type="text"
          className="font-semibold text-brand_primary cursor-pointer p-0"
        >
          {isEditing ? 'Update' : 'Add'}
        </Button>
      </div>
    </div>
  )
}

export default SegmentScreen
