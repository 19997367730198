export const TREE_DIMENSIONS = [
  'APP_VIEW_LDP',
  'APP_VIEW_CATE_OR_PRODUCT_LISTING',
  'APP_VIEW_PRODUCT_DETAIL',
  'APP_ADD_TO_CART_PRODUCT',
  'APP_REMOVE_FROM_CART',
  'APP_ABANDON_CART',
  'APP_REORDER_PRODUCT',
  'WEB_VIEW_LDP',
  'WEB_VIEW_CATE_OR_PRODUCT_LISTING',
  'WEB_VIEW_PRODUCT_DETAIL',
  'WEB_ADD_TO_CART_PRODUCT',
  'WEB_REMOVE_FROM_CART',
  'WEB_ABANDON_CART',
  'WEB_REORDER_PRODUCT',
  'PRODUCT',
  'PCM_PURCHASED_CATEGORY',
  'PCM_PURCHASED_SUB_CATEGORY',
  'PCM_LAST_PURCHASED_CATEGORY',
  'PCM_LAST_SUB_PURCHASED_CATEGORY',
  'PURCHASED_CATEGORY',
  'PURCHASED_SUBCATEGORY',
  'LAST_CATEGORY',
  'LAST_SUBCATEGORY',
  'PCM_PURCHASED_CATEGORY_COUNT',
  'APP_VIEW_CATE_OR_PRODUCT_LISTING_COUNT',
  'APP_VIEW_PRODUCT_DETAIL_COUNT',
  'WEB_VIEW_CATE_OR_PRODUCT_LISTING_COUNT',
  'WEB_VIEW_PRODUCT_DETAIL_COUNT',
  'PURCHASED_CATEGORY_COUNT',
  'KPOS_MBS_BY_PHONE',
  'KPOS_MBS_BY_APP',
  'PURCHASED_PRODUCT',
  'purchased_product',
  'APP_PURCHASE_PRODUCT_PCM',
  'WEB_PURCHASE_PRODUCT_PCM',
  'PURCHASE_PRODUCT_PCM',
  'MEMBERSHIP',
  'WEB_SEARCH_PRODUCT',
  'APP_SEARCH_PRODUCT',

  'webCategoryAddToCart',
  'appCategoryAddToCart',
]
