export function findAllParentOrMatchingNodes(
  node,
  targetNodeUuid = undefined,
  parentNodes: any = [],
  result: any[] = []
) {
  // If targetNodeUuid is undefined, collect all nodes of the specified types
  if (targetNodeUuid == null) {
    if (node?.nodeType === 'waitIn' || node?.nodeType === 'checkUserAttr') {
      result.push(node)
    }
  } else {
    // If targetNodeUuid is provided, check if the current node is the target
    if (node?.nodeUuid === targetNodeUuid) {
      return parentNodes.filter(
        (parent: any) => parent?.nodeType === 'waitIn' || parent?.nodeType === 'checkUserAttr'
      )
    }

    // If the current node matches the node types we're interested in, add it to the parentNodes
    if (node?.nodeType === 'waitIn' || node?.nodeType === 'checkUserAttr') {
      parentNodes.push(node)
    }
  }

  // If the current node has children, recursively search through them
  if (node?.childrenNodes && node.childrenNodes?.length > 0) {
    for (let child of node?.childrenNodes) {
      const childResult = findAllParentOrMatchingNodes(
        child,
        targetNodeUuid,
        [...parentNodes],
        result
      )
      if (targetNodeUuid != null && childResult) {
        return childResult // Return as soon as we find the target node
      }
    }
  }

  // If targetNodeUuid is undefined, return all collected results
  return targetNodeUuid == null ? result : null
}

// Wrapper function that initiates the search
export function getAllParentNodes(sample, targetNodeUuid = undefined) {
  return findAllParentOrMatchingNodes(sample, targetNodeUuid)
}

export function findNodePosition(node, nodeUuid, index = { count: 0 }) {
  // If the current node is the one we're looking for, return its position
  if (node.nodeUuid === nodeUuid) {
    return index.count + 1
  }

  // If the current node is not "virtual", increase the count
  if (node.nodeType !== 'virtual') {
    index.count++
  }

  // Traverse the childrenNodes if they exist
  if (node.childrenNodes && node.childrenNodes.length > 0) {
    for (let i = 0; i < node.childrenNodes.length; i++) {
      const position = findNodePosition(node.childrenNodes[i], nodeUuid, index)
      if (position !== undefined) {
        return position
      }
    }
  }

  // Return undefined if the nodeUuid was not found in this branch
  return undefined
}

export function formatData(data) {
  const result: any = []

  data?.forEach((item) => {
    const alias = item?.alias?.toString() // Ensure alias is 2 digits

    // If dynamicFields exist, process them
    {
      // If the 'value' is an array of objects (like in the third object)
      item?.value?.forEach((subItem) => {
        const code = subItem.code
        if (subItem?.dynamicFields) {
          subItem?.dynamicFields?.forEach((field) => {
            result?.push({
              ...field,
              parameter_code: `{{${alias}--${code}--${field?.parameter_code}}}`,
            })
          })
        }
      })
    }
  })

  return result
}

export function addDynamicParams(schemas, dynamicParamsArray) {
  return schemas?.map((schema) => {
    if (schema?.type === 'dynamic_content') {
      return {
        ...schema,
        dynamicParams: dynamicParamsArray, // Add the dynamicParams field
      }
    }
    return schema // Return the schema unchanged if type is not 'dynamic_content'
  })
}

export function splitValues(arr) {
  const aiValues: any = []
  const otherValues: any = []

  arr?.forEach((value) => {
    if (
      typeof value?.parameter_code === 'string' &&
      value?.parameter_code?.includes('ai_') &&
      value?.parameter_code?.endsWith('}}')
    ) {
      aiValues?.push(value)
    } else {
      otherValues?.push(value)
    }
  })

  return {
    aiValues,
    otherValues,
  }
}

export const AI_PARAMS = [
  '{{ai_product_variant_barcode}}',
  '{{ai_subject_id}}',
  '{{ai_product_name}}',
  '{{ai_cate_lv3_ol}}',
  '{{ai_emoji}}',
  '{{ai_slug}}',
  '{{ai_headline}}',
  '{{ai_body}}',
  '{{ai_deeplink_value}}',
  '{{ai_image}}',
  '{{ai_price_promotion}}',
  '{{ai_price_original}}',
  '{{ai_percent_discount}}',
  '{{ai_discount_value}}',
  '{{ai_sorting_score}}',
  '{{ai_cta}}',
  '{{ai_large_image}}',
]

export function isDirectChildOfAiCheck(node, childNodeUuid) {
  // Check if the current node is of type 'aiCheck'
  if (
    (node?.nodeType === 'aiCheck' || node?.nodeType === 'channel') &&
    node?.childrenNodes &&
    node.childrenNodes?.length > 0
  ) {
    // Check if any of the children match the given childNodeUuid
    for (let child of node.childrenNodes) {
      if (child?.nodeUuid === childNodeUuid) {
        return true // The childNodeUuid is a direct child of an aiCheck node
      }
    }
  }

  // If the current node has children, recursively search through them
  if (node?.childrenNodes && node.childrenNodes?.length > 0) {
    for (let child of node.childrenNodes) {
      const result = isDirectChildOfAiCheck(child, childNodeUuid)
      if (result) {
        return result // Return true as soon as we find a match
      }
    }
  }

  // Return false if no matching direct child was found
  return false
}

// Wrapper function that initiates the search
export function checkIfDirectChildOfAiCheck(sample, childNodeUuid) {
  return isDirectChildOfAiCheck(sample, childNodeUuid)
}

export function findNearestNodeOfType(node, targetType) {
  // Base case: if the node is of the target type, return it
  if (node.nodeType === targetType) {
    return node
  }

  // If the node has children, recursively check them for the nearest node of the target type
  if (node.childrenNodes && node.childrenNodes.length > 0) {
    for (let child of node.childrenNodes) {
      const foundNode = findNearestNodeOfType(child, targetType)
      if (foundNode) {
        return foundNode
      }
    }
  }

  // Return null if no node of the target type is found
  return null
}

export function findAllNodesOfType(node, targetType) {
  let result: any = []

  // Check if the current node is of the target type
  if (node?.nodeType === targetType) {
    result?.push(node)
  }

  // If the node has children, recursively check them
  if (node?.childrenNodes && node?.childrenNodes.length > 0) {
    for (let child of node?.childrenNodes) {
      result = result?.concat(findAllNodesOfType(child, targetType))
    }
  }

  return result
}

export function findClosestNodeOfType(node, targetType) {
  // Check if the current node is of the target type
  if (node?.nodeType === targetType) {
    return node
  }

  // If the node has children, recursively check them
  if (node?.childrenNodes && node?.childrenNodes.length > 0) {
    for (let child of node?.childrenNodes) {
      const foundNode = findClosestNodeOfType(child, targetType)
      if (foundNode) {
        return foundNode // Return as soon as we find the closest match
      }
    }
  }

  return null // No match found
}

export function checkAncestorType(node, targetType) {
  // Base case: if the node is of the target type, return it
  if (node.nodeType === targetType) {
    return true
  }

  // If the node has children, recursively check them for the nearest node of the target type
  if (node.childrenNodes && node.childrenNodes.length > 0) {
    for (let child of node.childrenNodes) {
      const check = checkAncestorType(child, targetType)
      if (check) {
        return check
      }
    }
  }

  // Return null if no node of the target type is found
  return false
}
