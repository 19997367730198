import axios from 'Utils/axios'
import { useEffect, useState } from 'react'

const LIMIT = 40

export interface IUseFetchDimensions {
  dimension_code?: string
}

export default function useFetchDimensions({ dimension_code }: IUseFetchDimensions) {
  const [options, setOptions] = useState([])
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const fetchDimensions = async (isLoadMore = false) => {
    if (loading) return 
    try {
      setLoading(true)
      const resp = await axios.get('/audience-dimensions/dimensions', {
        params: {
          dimension_code: dimension_code || 'PCM_PURCHASED_PRODUCT', // Sử dụng dimension_code từ props
          keyword: keyword || '',
          per_page: LIMIT,
          page: page,
        },
      })
      
      const newOptions = resp.data.data.map((op) => ({ 
        label: op?.name, 
        value: dimension_code == 'PCM_PURCHASED_PRODUCT'? `PRODUCT_${op?.code}` : op?.code, 
        ...op 
      }))

      setHasMore(newOptions.length === LIMIT)
      
      setOptions(prev => isLoadMore ? [...prev, ...newOptions] : newOptions)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const loadMore = () => {
    setPage(prev => prev + 1)
  }

  useEffect(() => {
    setPage(1)
    fetchDimensions()
  }, [keyword, dimension_code]) // Theo dõi cả dimension_code để fetch lại nếu thay đổi

  useEffect(() => {
    if (page > 1) {
      fetchDimensions(true)
    }
  }, [page])

  return {
    options,
    loading,
    setKeyword,
    hasMore,
    loadMore,
  }
}
